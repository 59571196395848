// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-data-deletion-js": () => import("./../../../src/pages/data-deletion.js" /* webpackChunkName: "component---src-pages-data-deletion-js" */),
  "component---src-pages-games-js": () => import("./../../../src/pages/games.js" /* webpackChunkName: "component---src-pages-games-js" */),
  "component---src-pages-index-copy-js": () => import("./../../../src/pages/index-copy.js" /* webpackChunkName: "component---src-pages-index-copy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-parents-guide-js": () => import("./../../../src/pages/parents-guide.js" /* webpackChunkName: "component---src-pages-parents-guide-js" */),
  "component---src-pages-partner-opt-out-js": () => import("./../../../src/pages/partner-opt-out.js" /* webpackChunkName: "component---src-pages-partner-opt-out-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-safe-fair-play-policy-js": () => import("./../../../src/pages/safe-fair-play-policy.js" /* webpackChunkName: "component---src-pages-safe-fair-play-policy-js" */),
  "component---src-pages-share-ns-the-war-js": () => import("./../../../src/pages/share/ns-the-war.js" /* webpackChunkName: "component---src-pages-share-ns-the-war-js" */),
  "component---src-pages-support-detail-js": () => import("./../../../src/pages/support-detail.js" /* webpackChunkName: "component---src-pages-support-detail-js" */),
  "component---src-pages-support-index-js": () => import("./../../../src/pages/support/index.js" /* webpackChunkName: "component---src-pages-support-index-js" */),
  "component---src-pages-support-no-surrender-js": () => import("./../../../src/pages/support/no-surrender.js" /* webpackChunkName: "component---src-pages-support-no-surrender-js" */),
  "component---src-pages-support-sample-js": () => import("./../../../src/pages/support-sample.js" /* webpackChunkName: "component---src-pages-support-sample-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-tester-strapi-support-page-slug-js": () => import("./../../../src/pages/tester/{StrapiSupportPage.slug}.js" /* webpackChunkName: "component---src-pages-tester-strapi-support-page-slug-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-careers-detail-js": () => import("./../../../src/templates/careers-detail.js" /* webpackChunkName: "component---src-templates-careers-detail-js" */),
  "component---src-templates-support-item-detail-js": () => import("./../../../src/templates/support-item-detail.js" /* webpackChunkName: "component---src-templates-support-item-detail-js" */)
}

